exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-multimedia-js": () => import("./../../../src/pages/multimedia.js" /* webpackChunkName: "component---src-pages-multimedia-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-painting-js": () => import("./../../../src/pages/painting.js" /* webpackChunkName: "component---src-pages-painting-js" */),
  "component---src-pages-pdfs-resume-js": () => import("./../../../src/pages/pdfs/resume.js" /* webpackChunkName: "component---src-pages-pdfs-resume-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-tufts-land-js": () => import("./../../../src/pages/tufts-land.js" /* webpackChunkName: "component---src-pages-tufts-land-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

